<mat-card class="center">
  <mat-card-header>
    <mat-card-title>Choose an Avatar</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if(formValue()){
      <img 
        mat-card-avatar 
        [src]="formValue()" 
        alt="Avatar" 
        class="avatar-img"/>

    }@else{
      <mat-icon
        class="avatar"
        [style.height.px]="30"
        [style.width.px]="30"
      >person</mat-icon>
    }
  </mat-card-content>
  <mat-card-actions>
    <button
      color="primary"
      mat-raised-button (click)="openDialog()">Select an Avatar</button>
  </mat-card-actions>
</mat-card>
