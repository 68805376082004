import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, Input, input, OnInit, Renderer2, signal, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarComponent, CloseMethod, DialogClosedProps } from '@shared/dialogs/avatar/avatar.component';

const ANGULAR_MATERIAL_MODULES = [
  MatIconModule, MatButtonModule,
  MatProgressSpinnerModule,
  MatCardModule,
];

const CORE_MODULES = [CommonModule];

@Component({
  standalone: true,
  imports: [
...CORE_MODULES,
    ...ANGULAR_MATERIAL_MODULES,
  ],
  selector: 'app-select-avatar-button',
  templateUrl: './select-avatar-button.component.html',
  styleUrls: ['./select-avatar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAvatarButtonComponent implements OnInit {
  photoUrlFromSocialMedia = input<string | null>(null);
  avatarFormControl = input.required<AbstractControl >();
  const;

  @ViewChild('avatarIconButton', { read: ElementRef, static: false })
  avatarIconButton!: ElementRef;
  formValue = signal<string| null>(null);

  constructor(
    private dialog: MatDialog,
    private render: Renderer2,
  ) { }

  ngOnInit() {
    this.avatarFormControl()?.valueChanges.subscribe((url) => {
      this.formValue.set(url);
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarComponent, {
      width: '600px',
      height: '675px',
      data: {
        showSocialMediaButton: !!this.photoUrlFromSocialMedia,
      },
      backdropClass: 'gth-overlay-backdrop',
    });

    dialogRef.afterClosed().subscribe((result: DialogClosedProps | null) => {
      // Don't change image if close method wasn't CloseMethod.SAVE
      if (!result || result.closeMethod !== CloseMethod.SAVE) return;
      // Change image to one from social media account ;)
      if (result.useSocialMedia && this.photoUrlFromSocialMedia) {
        console.log(this.photoUrlFromSocialMedia);

        this.avatarFormControl().setValue(this.photoUrlFromSocialMedia);
        return;
      }
      // Don't change image if no image ;)
      if (!result.selectedImage) return;
      this.avatarFormControl().setValue(result.selectedImage);
    });
  }

  showIcon() {
    this.render.removeClass(this.avatarIconButton.nativeElement, 'hidden');
  }

  hideIcon() {
    if (!this.avatarFormControl().value) return;
    this.render.addClass(this.avatarIconButton.nativeElement, 'hidden');
  }
}
